.aside {
    position: fixed;
    left: 0;
    top: 0;
    background: var(--body-color);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding: 40px;
    width: 110px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    transition: 0.3s;
}

.nav_list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.nav_link {
    font-size: 24px;
    color: var(--title-color);
    font-weight: var(--font-bold);
}

.nav_link:hover {
    color: hsl(43, 100%, 68%)
}

.nav_toogle {
    position: fixed;
    top: 20px;
    left: 30px;
    cursor: pointer;
    height: 40px;
    width: 45px;
    background-color: var(--body-color);
    border: 1px solid #e8dfec;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: 0.3s;
}

@media screen and (max-width: 1024px) {
    .aside {
      left: -110px;
    }

    .nav_toogle {
        display: flex;
    }

    .nav_toogle-open {
        left: 140px;
    }

    .show-menu {
        left: 0;
    }
}