.about_container {
    grid-template-columns: 3fr 9fr;
    column-gap: 30px;
}

.about_data {
    padding: 30px;
    background-color: var(--container-color);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    grid-template-columns: repeat(1, 1fr);
    column-gap: 30px;
    align-items: flex-start;
    position: relative;
}

.about_data::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid var(--container-color);
    position: absolute;
    left: 15px;
    top: 20%;
}

.about_description {
    margin-bottom: 16px;
    text-align: justify;
}

.about_img {
    width: 200px;
    border-radius: 50%;
}

@media screen and (max-width: 1024px) {
    .about_container {
      grid-template-columns: 720px;
      justify-content: center;
      row-gap: 30px;
    }

    .about_data::before {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        left: 49%;
        top: -20px;
    }

    .about_img {
        justify-self: center;
    }
}

@media screen and (max-width: 768px) {
    .about_container {
      grid-template-columns: 350px;
    }

    .about_data::before {
        left: 47%;
    }

    .about_data {
        grid-template-columns: 1fr;
        row-gap: 1;
    }

    .about_img {
        justify-self: center;
    }
}

@media screen and (max-width: 576px) {
    .about_container {
      grid-template-columns: 320px;
    }
}

@media screen and (max-width: 350px) {
    .about_container {
      grid-template-columns: 1fr;
    }
}