.home {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro {
    max-width: 540px;
    text-align: center;
}

.home_img {
    margin-bottom: 24px;
    border-radius: 50%;
    width: 200px;
}

.home_name {
    font-size: var(--h1-font-size);
    font-weight: var(--font-bold);
    margin-bottom: 8px;
}

.home_socials {
    display: flex;
    column-gap: 28px;
    margin: 24px 0;
    justify-content: center;
}

.home_social-link {
    color: var(--title-color);
    font-size: 21px;
    transition: 0.3s ;
}

.home_social-link:hover {
    color: hsl(43, 100%, 68%)
}

@media screen and (max-width: 1024px) {
    .home_social-link {
      font-size: 18px;
    }
}