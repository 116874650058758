.resume_container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
}

.timeline {
    background-color: var(--container-color);
    padding: 30px;
    border-radius: var(--border-radius);
    position: relative;
    box-shadow: var(--shadow);
}

.timeline_item {
    position: relative;
    padding-left: 50px;
    padding-bottom: 50px;
}

.timeline_item:last-child {
    padding-bottom: 0;
}

.timeline_item::before {
    content: '';
    width: 1px;
    height: 100%;
    background-color: var(--first-color);
    position: absolute;
    left: 4px;
    top: 0;
}

.timeline .icon-briefcase,
.timeline .icon-graduation {
    position: absolute;
    left: -7px;
    top: 0;
    font-size: var(--h2-font-size);
    color: var(--first-color);
    background-color: var(--container-color);
    padding: 7px 0;
}

.timeline_date {
    color: #8b88b1;
    font-size: var(--smaller-font-size);
}

.timeline_title {
    font-size: var(--h3-font-size);
    margin: 8px 0;
}

.timeline_organization {
    color: #A9A9A9;
    font-size: var(--small-font-size);
    margin: 8px 0;
}

.timeline_desc {
    font-size: var(--small-font-size);
    margin: 8px 0;
}

@media screen and (max-width: 1024px) {
    .resume_container {
      grid-template-columns: 450px;
      justify-content: center;
      row-gap: 30px;
    }

    .timeline_item::before {
        left: 2px;
    }
}

@media screen and (max-width: 576px) {
    .resume_container {
      grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 350px) {
    .timeline_item {
      padding-left: 30px;
      padding-bottom: 30px;
    }

    .timeline_item::before {
        left: 0;
    }

    .timeline .icon-briefcase,
    .timeline .icon-graduation {
        left: -9px;
    }

    .timeline_title {
        text-align: justify;
    }
}