.work_container {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

.work_card {
    position: relative;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
}

.work_mask {
    background: #6c6ce5;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transition: 0.3s;
    opacity: 0;
}

.work_card:hover .work_mask {
    opacity: 0.9;
}

.work_category {
    color: #fff;
    background-color: var(--primary-color);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    position: absolute;
    top: 0;
    left: 24px;
    font-size: var(--small-font-size);
    display: inline-block;
    padding: 3px 10px;
    transform: translateY(-40px);
    opacity: 0;
    transition: 0.3s;
}

.work_title {
    color: #fff;
    font-size: var(--h3-font-size);
    margin: 0 0 16px;
    padding: 0 20px;
    position: absolute;
    top: 60px;
    transform: translateY(30px);
    transition: 0.3s;
    opacity: 0;
}

.work_button {
    color: #fff;
    position: absolute;
    bottom: 24px;
    left: 24px;
    font-size: var(--h3-font-size);
    display: block;
    background-color: #ffd15c;
    height: 40px;
    width: 40px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    transition: 0.3s;
    opacity: 0;
}

.work_card:hover .work_button {
    opacity: 1;
}

.work_card:hover .work_title,
.work_card:hover .work_category {
    opacity: 1;
    transform: translateY(0);
}

@media screen and (max-width: 1024px) {
    .work_container {
      grid-template-columns: repeat(2, 330px);
      justify-content: center;
      row-gap: 30px;
    }
}

@media screen and (max-width: 768px) {
    .work_container {
      grid-template-columns: 310px;
    }
}

@media screen and (max-width: 350px) {
    .work_container {
      grid-template-columns: 1fr;
    }
}